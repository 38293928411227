@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


*{
    box-sizing: border-box;
}

//colors//
$primary-color: #ed2d2f;
//colors//

body{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

.login{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(83, 24, 24, 0.63);
    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;

    .main{
        width: 50%;
        height: 70%;
        background-color: white;
        display: flex;
        flex-direction: column;
        border: 3px solid;
        border-radius: 15px;
        box-sizing: border-box;
        position: relative;

        .nav{
            width: 100%;
            height: 10%;
            background-color: $primary-color;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 1% 2%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;

            .login_register{
                display: flex;

                .logregbtn{  
                    background-color: $primary-color;
                    color: white;
                    border: none;
                    border-radius: 7px;
                    outline: none;
                    margin-left: 10%;
                    padding:5% 10%;
                    font-weight: bold;
                }
                .logregbtn:hover{
                    background-color: rgb(170, 0, 0);
                }
                .logregbtn:active{
                    background-color: rgb(170, 0, 0);
                }
            }

            .close{
                .closebtn{
                    background-color: transparent;
                    border: none;
                    outline: none;
                    font-size: 1rem;
                    color: white;
                }
                .closebtn:hover{
                    color:rgb(170, 0, 0)
                }
            }
        }

        .loginpage{
            width: 100%;
            height: 90%;
            padding: 0;
            margin: 2% 0 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            p{
                font-size: 1.1rem;
            }

            .formsection{
                width: 100%;
                margin: 2% 0 0 0;
                
                .field{
                    width: 100%;
                    height: 9vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 0;
                    .inputgroup{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        padding: 0;
                        .labels{
                            width: 10%;
                            font-size: 1.1rem;
                            color: rgba(0, 0, 0, 0.664);
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            margin-right: 5%;
                        }
                        .loginputs{
                            width: 50%;
                            height: 6vh;
                            position: relative;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            margin-right: 10%;
                            padding: 0 1%;
                            border: 0.01rem solid grey;
                            border-radius: 5px;
                            outline: none;
                        } 
                        .loginputs:focus{
                            border: 2px solid rgba(125, 200, 250, 0.795);
                            box-shadow: #ed2d301e 2px 3px;
                        }  
                    }
                    .error_message{
                        font-size: 0.7rem;
                        word-spacing: 2px;
                        color: red;
                        transition: ease-in-out 200ms;
                        margin: 0%;
                    }
                }
                .loginbtn{
                    width: 20%;
                    padding: 1% 3%;
                    margin: 0 0 0 40%;
                    border: none;
                    border-radius: 5px;
                    background-color: rgba(0, 138, 138, 0.63);
                    color: white;
                    font-size: 1.1rem;
                    font-weight: bold;
                }
                .loginbtn:hover{
                    background-color: rgba(0, 138, 138, 0.767);
                    box-shadow: rgba(0, 195, 255, 0.363) 2px 3px;
                } 
                
            }

            .sociallogin{
                .or{  
                    margin-top: 5%;
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                }
                hr{
                    width: 100%;
                    height: 2px;
                    margin: 0 2%;
                }
            }
        }
        .non{
            display: none;
        }
        .message_success{
            width: 100%;
            position: absolute;
            bottom: 0;
            text-align: center;
            font-size: 1.1rem;
            color: white;
            background-color: green;
            border-bottom-left-radius: 11px;
            border-bottom-right-radius: 11px;
            box-shadow: green 2px 2px;
        }
    }
}
