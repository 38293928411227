@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


*{
    box-sizing: border-box;
}

//colors//
$primary-color: #ed2d2f;
//colors//

.RedWolf{
    position: relative;
    width: 100%;
    min-height: 200vh;
    max-height: 700vh;
    margin: 0;
    padding: 0;

    .home{
        height: 500vh;
    }

    .contactsection{
        height: 200vh;
    }
}

body{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}


//Home//

.featuredthemes{
    margin: 2.2% 0 2% 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .head{
        font-size: 1.7rem;
        font-weight: bold;
        color: rgba(88, 89, 91, 1);
    }

    .circularimg{
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;

        .theme{
            width: 13%;
            height: 20%;

            img{
                width: 100%;
            }
            img:hover{
                border: 5px solid aqua;
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }
}


.newarrivals{
    margin: 3% 0 2% 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .head{
        font-size: 1.7rem;
        font-weight: bold;
        color: rgba(88, 89, 91, 1);
    }

    .arrivals{
        margin: 2% 0 0 0;
        width: 100%;
        height: 75vh;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;

        a{
            width: 100%;
            height: 100%;
            img{
            width: 95%;
            height: 100%;
            }
        }
    }
}


//official Merchandise Section//
.officialmerchandise{
    margin: 4% 0 2% 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .head{
        font-size: 1.7rem;
        font-weight: bold;
        color: rgba(88, 89, 91, 1);
    }

    .merchandises{
        width: 82%;
        height: 32vh;
        margin: 2.5% 0 2.5% 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        
        .item{
            width: 20%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            text-decoration: none;
            color: rgba(88, 89, 91, 1);

            img{
            width: 90%;
            min-height: 97%;
            border: 0.1rem solid black;
            border-radius: 10px;
            }

            .title{
                height: 3%;
                text-align: center;
                color: $primary-color;
                font-size: 1rem;
                font-weight: bold;
                padding-right: 2%;
            }

            .price{
                font-size: 0.9rem;
                font-weight: bold;
                text-align: center;
            }
        
        }
    }
}

//official Merchandise Section//


//MugsSection//
.mugsection{
    margin: 5% 0 2% 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    .head{
        font-size: 1.7rem;
        font-weight: bold;
        color: rgba(88, 89, 91, 1);
    }

    .mug_items{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        box-sizing: border-box;
        padding: 0 5%;

        .mug_info{
            width: 22%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: left;

            .mug_img{
                width: 100%;
            }
            
            .mug_title{
                font-size: 0.8rem;
                text-align: left;
                margin: 0;
                padding-left: 3%;
            }
            hr{
                margin: 0 0 0 3%;
                width: 85%;
            }
            .mug_price{
                margin: 1% 0;
                font-size: 0.8rem;
                font-weight: bold;
                text-align: left;
                padding-left: 3%;
            }
        }
    }
}
//MugsSection//


//Home//



//Footer//

.footer{
    position: absolute;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    box-sizing: border-box;

    .redstripe{
        width:100%;
        background-color: $primary-color;
        color: white;
        text-align: center;
        height: 10vh;
        font-size: 2.7rem;
        margin: 0;
    }
    
    .customercount{
        width: 100%;
        text-align: center;
        font-size: 2rem;
        margin: 1% 0;

        span{
            font-weight: bolder;
        }
    }
    
    .foot{
        background-color: rgba(185, 185, 185, 0.507);
        margin: 0;
        padding: 1% 3% 0.009% 3%;

        .footer-links{
            margin: 0;
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            
            .link{
                width: 80%;
                display: flex;
                justify-content: space-around;

                .links{
                    display: flex;
                    flex-direction: column;
                    padding: 0 2%;
                    font-size: 0.8rem;
                    line-height: 1.9rem;

                    h3{ 
                        color: $primary-color;
                        font-weight: bold;
                        font-size: 1.2rem;
                        margin: 0;
                    }
                    a{
                        color: grey;
                        text-decoration: none;
                        font-weight: bold;
                    }
                }
            }
            
            .followus{
                width: 20%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 7% 1.5% 0 1%;
                color: grey;
                margin: 0;
                
                a{
                    font-size: 1.5rem;
                    :hover{
                        transform: translateY(-2px);
                    }
                }
                .fb{
                    color: #4267B2;
                }

                .in{
                    color: #bc2a8d;
                }

                .tw{
                    color: #00acee;
                }
            }
        }
        .end{
            text-align: center;
            margin: 0;
            padding: 0;
            color: rgba(128, 128, 128, 0.644);
            font-size: 0.75rem;
            a{
                text-decoration: none;
            }
        } 
    }   
}

//Footer//