@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


*{
    box-sizing: border-box;
}

//colors//
$primary-color: #ed2d2f;
//colors//


//Navbar//

.Navbar{
    width: 100%;
    height: 12vh;
    position: relative;
    box-sizing: border-box;
    letter-spacing: 1px;

    .title{
        width: 12%;
        position: absolute;
        top: 0;
        z-index: 1;
        box-sizing: border-box;
        margin: 0;
        height: 12vh;
        
        .headerimg{
            width: 100%;
            height: 100%;
        }
    }

    .nav1{
        width: 100%;
        height: 36%;
        background-color: $primary-color;
        box-sizing: border-box;
        padding: 0 3% 0 0;
        
        .nav1-links{
            max-width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding-left: 65%;
            box-sizing: border-box;
            font-size: 0.8rem;

            .search{
                width: 45%;
                height: 85%;
                display: inline-flex;
                position: relative;
                border: 1px solid #ccc;
                border-radius: 5px;
                overflow: hidden;
                box-sizing: border-box;
                
                .search_input{
                    width: 100%;
                    height: 100%;
                    outline: none;
                    border: none;

                    :focus{
                        color: #00B4CC;
                    }
                }
                
                .search_button{
                    height: 100%;
                    border: none;
                    padding: 0.1rem;
                    background-color: white;
                    color: grey;
                }
            }
        }

        a{
            color: white;
            text-decoration: none;
        }
    }

    .nav2{
        width: 100%;
        height: 64%;
        color: rgba(88, 89, 91, 1);
        font-weight: bold;
        box-shadow: 0 2px 3px rgba(207, 41, 41, 0.315);
        box-sizing: border-box;
        display: flex;
        justify-content: space-evenly;
        padding: 0 3% 0 13%;
        font-size: 90%;

        .dropdowns{
            width: 86%;
            display: flex;
            justify-content: space-evenly;
            position: relative;

            .drophead{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                border: none;
                color: inherit;
                outline: none;
                border-radius: 0;
                margin: 0;
                padding: 9px;
                .down{
                    margin-left: 5px;
                    font-size: 0.6rem;
                }
                .submenu{
                    width: auto;
                    display: none;
                    position: absolute;
                    border: 2px solid;
                    border-radius: 10px;
                    background-color: white;
                    box-sizing: border-box;
                    transform: translateY(150px);
                    
                    .dropsections{
                        width: 100%;
                        padding: 1% 25%;
                        display: flex;
                        justify-content: space-around;
                    }
                }
            }
            .drophead:hover{
                color: $primary-color;
                border-bottom: 5px solid $primary-color;
                background-color: rgba(230, 230, 230, 0.363);
                cursor: pointer;
            }
        }

        .nav2-links{
            width: 14%;
            display: flex;
            justify-content: space-evenly;
            .icons{ 
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                a{
                    color: rgba(88, 89, 91, 1);
                    font-size: 1.5rem;
                }
            } 
            .icons:hover{
                a{
                color: $primary-color;
                }
                border-bottom: 5px solid $primary-color;
                background-color: rgba(230, 230, 230, 0.363);
                
            }    
        }
    }
}

//Navbar//