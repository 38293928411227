.contactsection{
    margin: 2% 0 0 0;

    .manageorder{
        text-align: center;
        color: grey;

        .manage-icons{
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            font-size: 2rem;
            a{text-decoration: none;
            color: grey;}
            i{
                display: flex;
                flex-direction: column;
            }
        }
    }
}