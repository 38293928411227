@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.RedWolf {
  position: relative;
  width: 100%;
  min-height: 200vh;
  max-height: 700vh;
  margin: 0;
  padding: 0;
}

.RedWolf .home {
  height: 500vh;
}

.RedWolf .contactsection {
  height: 200vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.featuredthemes {
  margin: 2.2% 0 2% 0;
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.featuredthemes .head {
  font-size: 1.7rem;
  font-weight: bold;
  color: #58595b;
}

.featuredthemes .circularimg {
  width: 80%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.featuredthemes .circularimg .theme {
  width: 13%;
  height: 20%;
}

.featuredthemes .circularimg .theme img {
  width: 100%;
}

.featuredthemes .circularimg .theme img:hover {
  border: 5px solid aqua;
  border-radius: 50%;
  cursor: pointer;
}

.newarrivals {
  margin: 3% 0 2% 0;
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.newarrivals .head {
  font-size: 1.7rem;
  font-weight: bold;
  color: #58595b;
}

.newarrivals .arrivals {
  margin: 2% 0 0 0;
  width: 100%;
  height: 75vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.newarrivals .arrivals a {
  width: 100%;
  height: 100%;
}

.newarrivals .arrivals a img {
  width: 95%;
  height: 100%;
}

.officialmerchandise {
  margin: 4% 0 2% 0;
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.officialmerchandise .head {
  font-size: 1.7rem;
  font-weight: bold;
  color: #58595b;
}

.officialmerchandise .merchandises {
  width: 82%;
  height: 32vh;
  margin: 2.5% 0 2.5% 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.officialmerchandise .merchandises .item {
  width: 20%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-decoration: none;
  color: #58595b;
}

.officialmerchandise .merchandises .item img {
  width: 90%;
  min-height: 97%;
  border: 0.1rem solid black;
  border-radius: 10px;
}

.officialmerchandise .merchandises .item .title {
  height: 3%;
  text-align: center;
  color: #ed2d2f;
  font-size: 1rem;
  font-weight: bold;
  padding-right: 2%;
}

.officialmerchandise .merchandises .item .price {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
}

.mugsection {
  margin: 5% 0 2% 0;
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.mugsection .head {
  font-size: 1.7rem;
  font-weight: bold;
  color: #58595b;
}

.mugsection .mug_items {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 5%;
}

.mugsection .mug_items .mug_info {
  width: 22%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
}

.mugsection .mug_items .mug_info .mug_img {
  width: 100%;
}

.mugsection .mug_items .mug_info .mug_title {
  font-size: 0.8rem;
  text-align: left;
  margin: 0;
  padding-left: 3%;
}

.mugsection .mug_items .mug_info hr {
  margin: 0 0 0 3%;
  width: 85%;
}

.mugsection .mug_items .mug_info .mug_price {
  margin: 1% 0;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: left;
  padding-left: 3%;
}

.footer {
  position: absolute;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.footer .redstripe {
  width: 100%;
  background-color: #ed2d2f;
  color: white;
  text-align: center;
  height: 10vh;
  font-size: 2.7rem;
  margin: 0;
}

.footer .customercount {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  margin: 1% 0;
}

.footer .customercount span {
  font-weight: bolder;
}

.footer .foot {
  background-color: rgba(185, 185, 185, 0.507);
  margin: 0;
  padding: 1% 3% 0.009% 3%;
}

.footer .foot .footer-links {
  margin: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.footer .foot .footer-links .link {
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.footer .foot .footer-links .link .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0 2%;
  font-size: 0.8rem;
  line-height: 1.9rem;
}

.footer .foot .footer-links .link .links h3 {
  color: #ed2d2f;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0;
}

.footer .foot .footer-links .link .links a {
  color: grey;
  text-decoration: none;
  font-weight: bold;
}

.footer .foot .footer-links .followus {
  width: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 7% 1.5% 0 1%;
  color: grey;
  margin: 0;
}

.footer .foot .footer-links .followus a {
  font-size: 1.5rem;
}

.footer .foot .footer-links .followus a :hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.footer .foot .footer-links .followus .fb {
  color: #4267B2;
}

.footer .foot .footer-links .followus .in {
  color: #bc2a8d;
}

.footer .foot .footer-links .followus .tw {
  color: #00acee;
}

.footer .foot .end {
  text-align: center;
  margin: 0;
  padding: 0;
  color: rgba(128, 128, 128, 0.644);
  font-size: 0.75rem;
}

.footer .foot .end a {
  text-decoration: none;
}
