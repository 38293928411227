@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(83, 24, 24, 0.63);
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.login .main {
  width: 50%;
  height: 70%;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 3px solid;
  border-radius: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}

.login .main .nav {
  width: 100%;
  height: 10%;
  background-color: #ed2d2f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1% 2%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.login .main .nav .login_register {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.login .main .nav .login_register .logregbtn {
  background-color: #ed2d2f;
  color: white;
  border: none;
  border-radius: 7px;
  outline: none;
  margin-left: 10%;
  padding: 5% 10%;
  font-weight: bold;
}

.login .main .nav .login_register .logregbtn:hover {
  background-color: #aa0000;
}

.login .main .nav .login_register .logregbtn:active {
  background-color: #aa0000;
}

.login .main .nav .close .closebtn {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1rem;
  color: white;
}

.login .main .nav .close .closebtn:hover {
  color: #aa0000;
}

.login .main .loginpage {
  width: 100%;
  height: 90%;
  padding: 0;
  margin: 2% 0 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.login .main .loginpage p {
  font-size: 1.1rem;
}

.login .main .loginpage .formsection {
  width: 100%;
  margin: 2% 0 0 0;
}

.login .main .loginpage .formsection .field {
  width: 100%;
  height: 9vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
}

.login .main .loginpage .formsection .field .inputgroup {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
}

.login .main .loginpage .formsection .field .inputgroup .labels {
  width: 10%;
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.664);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 5%;
}

.login .main .loginpage .formsection .field .inputgroup .loginputs {
  width: 50%;
  height: 6vh;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 10%;
  padding: 0 1%;
  border: 0.01rem solid grey;
  border-radius: 5px;
  outline: none;
}

.login .main .loginpage .formsection .field .inputgroup .loginputs:focus {
  border: 2px solid rgba(125, 200, 250, 0.795);
  -webkit-box-shadow: #ed2d301e 2px 3px;
          box-shadow: #ed2d301e 2px 3px;
}

.login .main .loginpage .formsection .field .error_message {
  font-size: 0.7rem;
  word-spacing: 2px;
  color: red;
  -webkit-transition: ease-in-out 200ms;
  transition: ease-in-out 200ms;
  margin: 0%;
}

.login .main .loginpage .formsection .loginbtn {
  width: 20%;
  padding: 1% 3%;
  margin: 0 0 0 40%;
  border: none;
  border-radius: 5px;
  background-color: rgba(0, 138, 138, 0.63);
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
}

.login .main .loginpage .formsection .loginbtn:hover {
  background-color: rgba(0, 138, 138, 0.767);
  -webkit-box-shadow: rgba(0, 195, 255, 0.363) 2px 3px;
          box-shadow: rgba(0, 195, 255, 0.363) 2px 3px;
}

.login .main .loginpage .sociallogin .or {
  margin-top: 5%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.login .main .loginpage .sociallogin hr {
  width: 100%;
  height: 2px;
  margin: 0 2%;
}

.login .main .non {
  display: none;
}

.login .main .message_success {
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  font-size: 1.1rem;
  color: white;
  background-color: green;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  -webkit-box-shadow: green 2px 2px;
          box-shadow: green 2px 2px;
}
