.contactsection {
  margin: 2% 0 0 0;
}

.contactsection .manageorder {
  text-align: center;
  color: grey;
}

.contactsection .manageorder .manage-icons {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  font-size: 2rem;
}

.contactsection .manageorder .manage-icons a {
  text-decoration: none;
  color: grey;
}

.contactsection .manageorder .manage-icons i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
