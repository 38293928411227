@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.Navbar {
  width: 100%;
  height: 12vh;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  letter-spacing: 1px;
}

.Navbar .title {
  width: 12%;
  position: absolute;
  top: 0;
  z-index: 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  height: 12vh;
}

.Navbar .title .headerimg {
  width: 100%;
  height: 100%;
}

.Navbar .nav1 {
  width: 100%;
  height: 36%;
  background-color: #ed2d2f;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 3% 0 0;
}

.Navbar .nav1 .nav1-links {
  max-width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 65%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 0.8rem;
}

.Navbar .nav1 .nav1-links .search {
  width: 45%;
  height: 85%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.Navbar .nav1 .nav1-links .search .search_input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
}

.Navbar .nav1 .nav1-links .search .search_input :focus {
  color: #00B4CC;
}

.Navbar .nav1 .nav1-links .search .search_button {
  height: 100%;
  border: none;
  padding: 0.1rem;
  background-color: white;
  color: grey;
}

.Navbar .nav1 a {
  color: white;
  text-decoration: none;
}

.Navbar .nav2 {
  width: 100%;
  height: 64%;
  color: #58595b;
  font-weight: bold;
  -webkit-box-shadow: 0 2px 3px rgba(207, 41, 41, 0.315);
          box-shadow: 0 2px 3px rgba(207, 41, 41, 0.315);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding: 0 3% 0 13%;
  font-size: 90%;
}

.Navbar .nav2 .dropdowns {
  width: 86%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  position: relative;
}

.Navbar .nav2 .dropdowns .drophead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: transparent;
  border: none;
  color: inherit;
  outline: none;
  border-radius: 0;
  margin: 0;
  padding: 9px;
}

.Navbar .nav2 .dropdowns .drophead .down {
  margin-left: 5px;
  font-size: 0.6rem;
}

.Navbar .nav2 .dropdowns .drophead .submenu {
  width: auto;
  display: none;
  position: absolute;
  border: 2px solid;
  border-radius: 10px;
  background-color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transform: translateY(150px);
          transform: translateY(150px);
}

.Navbar .nav2 .dropdowns .drophead .submenu .dropsections {
  width: 100%;
  padding: 1% 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.Navbar .nav2 .dropdowns .drophead:hover {
  color: #ed2d2f;
  border-bottom: 5px solid #ed2d2f;
  background-color: rgba(230, 230, 230, 0.363);
  cursor: pointer;
}

.Navbar .nav2 .nav2-links {
  width: 14%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.Navbar .nav2 .nav2-links .icons {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Navbar .nav2 .nav2-links .icons a {
  color: #58595b;
  font-size: 1.5rem;
}

.Navbar .nav2 .nav2-links .icons:hover {
  border-bottom: 5px solid #ed2d2f;
  background-color: rgba(230, 230, 230, 0.363);
}

.Navbar .nav2 .nav2-links .icons:hover a {
  color: #ed2d2f;
}
